































































































































































































































































































































































































































.MhDevPanel {
	position: relative;
	padding: 1rem;
	padding-bottom: 2rem;
	font-size: 13px;
	line-height: 1.3em;
	background-color: lighten( cyan, 30 );
	color: black;

	&__inner {}

	.routerLink--exactActive {
		//font-weight: bold;
		color: red;
	}

	&__blocks {
		display: grid;
		grid-gap: 0.5em;
		//grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

		& + & { margin-top: 0.5em; }
	}
	&__block {
		border: 1px solid fade( black, 25 );
		display: flex;
		flex-direction: column;

		&Header {
			padding: 0.25rem;
			background-color: fade(white, 25);
			background-color: fade(black, 10);
			border-bottom: 1px solid fade( black, 25 );
			font-weight: bold;
		}
		&Body {
			flex-grow: 1;
			padding: 0.25rem;
			background-color: fade(black, 20);
		}

		&Link {
			display: block;
			padding: 0.25em 0;
		}
		&Link:not(:last-child) {
			border-bottom: 1px dotted fade(black, 25);
		}
		&Link:hover {
			background-color: fade( black, 5 );
		}
	}

	@media screen and (min-width:750px) {
		&__block--span-2 { grid-column: span 2; }
	}
}

.MhDevPanel { // table styling
	table { table-layout: fixed; border-collapse: collapse; width: 100%; }
	thead tr { border-bottom: 1px solid fade(black, 50); }
	thead th { opacity: 0.35; font-variant: small-caps; letter-spacing: 0.05em; }
	tbody tr:not(:last-child) { border-bottom: 1px dotted fade(black, 25); }
	td, th { xxfont-weight: 400; text-align: left; padding: 0.25em 0.5em 0.25em 0; }
	//tr > *:first-child { min-width: 33%; } // klappt das wirklich immer?
}
