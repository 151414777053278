.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .ZaAppFooter__colum {
  outline: 1px dotted magenta;
}
.ZaAppFooter__inner {
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
@media (min-width: 1023px ) {
  .ZaAppFooter__colums {
    display: grid;
    grid-column-gap: var(--app-content-gridGap);
    grid-template-columns: repeat(12, 1fr);
  }
  .ZaAppFooter__colums > * {
    min-height: 0;
    min-width: 0;
  }
  .ZaAppFooter__colum--1 {
    grid-column: 1 / span 5;
  }
  .ZaAppFooter__colum--2 {
    grid-column: 6 / span 4;
  }
  .ZaAppFooter__colum--3 {
    grid-column: 10 / span 3;
  }
}
.ZaAppFooter {
  background: var(--color-app-footer-background);
  color: var(--color-app-footer-color);
}
.ZaAppFooter__colum {
  padding-bottom: 1.5em;
}
.ZaAppFooter__brand a {
  color: inherit;
}
@media (min-width: 1023px ) {
  .ZaAppFooter {
    padding-top: 3.25em;
    padding-bottom: 5rem;
  }
  .ZaAppFooter__brand {
    font-size: 28px;
    line-height: 1.35em;
  }
}
@media (max-width: 1023px ) {
  .ZaAppFooter {
    padding-top: 1.25em;
    padding-bottom: 5rem;
  }
}
