.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .ZaServices__service {
  outline: 1px dotted red;
}
[showBorders3] .ZaServices__service > * {
  outline: 1px dotted magenta;
}
.ZaServices {
  --background: #F0ECD3;
}
.ZaServices__inner {
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
.ZaServices__serviceTitleWrapper {
  display: flex;
}
.ZaServices__serviceTitle {
  flex-grow: 1;
}
@media (min-width: 1023px ) {
  .ZaServices__services {
    display: grid;
    grid-column-gap: var(--app-content-gridGap);
    grid-row-gap: var(--app-content-gridGap);
    grid-template-columns: repeat(12, 1fr);
  }
  .ZaServices__services > * {
    min-height: 0;
    min-width: 0;
  }
  .ZaServices__service:nth-child( odd ) {
    grid-column: 2 / span 5;
  }
  .ZaServices__service:nth-child( even ) {
    grid-column: 8 / span 5;
  }
  .ZaServices__toggleIndicator {
    display: none;
  }
}
.ZaServices {
  background-color: var(--background);
}
@media (min-width: 1023px ) {
  .ZaServices {
    padding-top: 0rem;
    padding-bottom: 2rem;
  }
  .ZaServices__services {
    padding-top: 0.25em;
  }
  .ZaServices__serviceTitle {
    font-size: 28px;
    line-height: 1.35em;
  }
}
@media (max-width: 1023px ) {
  .ZaServices__toggleIndicator {
    margin-top: 0.15em;
  }
  .ZaServices__service + .ZaServices__service {
    padding-top: 0.5rem;
    border-top: 1px solid;
  }
  .ZaServices__serviceTitleWrapper {
    padding-bottom: 0.5rem;
    cursor: pointer;
  }
  .ZaServices__serviceTitle {
    font-weight: bold;
  }
  .ZaServices__serviceText > *:last-child {
    padding-bottom: 1.5em;
  }
}
