.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .ZaCoronaMessage__inner > * {
  outline: 1px dotted magenta;
}
.ZaCoronaMessage__inner {
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
.ZaCoronaMessage__titleWrapper {
  display: flex;
}
.ZaCoronaMessage__title {
  flex-grow: 1;
}
@media (min-width: 1023px ) {
  .ZaCoronaMessage__inner {
    display: grid;
    grid-column-gap: var(--app-content-gridGap);
    grid-template-columns: repeat(12, 1fr);
  }
  .ZaCoronaMessage__inner > * {
    min-height: 0;
    min-width: 0;
  }
  .ZaCoronaMessage__titleWrapper {
    grid-column: 1 / span 5;
  }
  .ZaCoronaMessage__toggleIndicator {
    display: none;
  }
  .ZaCoronaMessage__text {
    grid-column: 7 / span 5;
  }
}
.ZaCoronaMessage {
  /*
	h1 { font-size: 74px; line-height: 1.2em; }
	h3 { font-size: 28px; line-height: 1.35em; }
	ul, ol { padding-left: 1.65rem; }
	li { padding-left: 0.5rem; }
	*/
}
@media (min-width: 1023px ) {
  .ZaCoronaMessage {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ZaCoronaMessage__title {
    font-size: 46px;
    line-height: 1.2em;
  }
}
@media (max-width: 1023px ) {
  .ZaCoronaMessage {
    background-color: #FDF5E8;
  }
  .ZaCoronaMessage__title {
    font-weight: bold;
  }
  .ZaCoronaMessage__titleWrapper {
    padding: 1rem 0;
    cursor: pointer;
  }
  .ZaCoronaMessage__toggleIndicator {
    margin-top: 0.15em;
  }
  .ZaCoronaMessage__text > *:last-child {
    padding-bottom: 1.5em;
  }
}
