.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .ZaPageHeader__inner > * {
  outline: 1px dotted magenta;
}
.ZaPageHeader__inner {
  position: relative;
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.ZaPageHeader__sticker {
  position: absolute;
  right: 0rem;
  display: none;
}
.ZaPageHeader__title {
  flex-grow: 1;
}
.ZaPageHeader__titleInner {
  align-items: center;
  display: flex;
}
@media (min-width: 1023px ) {
  .ZaPageHeader__sticker {
    top: 5.75rem;
    width: 218px;
    height: 218px;
  }
  .ZaPageHeader__title {
    display: grid;
    grid-column-gap: var(--app-content-gridGap);
    grid-template-columns: repeat(12, 1fr);
  }
  .ZaPageHeader__title > * {
    min-height: 0;
    min-width: 0;
  }
  .ZaPageHeader__titleInner {
    grid-column: 2 / span 8;
  }
}
@media (max-width: 1023px ) {
  .ZaPageHeader__sticker {
    top: 2rem;
    width: 122px;
    height: 122px;
  }
  .ZaPageHeader__title {
    display: flex;
  }
}
.ZaPageHeader__brand {
  padding-top: 2.6rem;
  line-height: 1.06em;
  font-weight: bold;
}
.ZaPageHeader__brand a {
  color: inherit;
}
.ZaPageHeader__sticker {
  background-image: url('../assets/imgs/alles-wird-gut-sticker.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ZaPageHeader__title {
  color: #2A4960;
}
.ZaPageHeader--type-home {
  background: #fcdfa9;
  background: linear-gradient(135deg, #fcdfa9 0%, #ffcda7 100%);
}
.ZaPageHeader--type-home .ZaPageHeader__inner {
  height: 100vh;
}
.ZaPageHeader--type-home .ZaPageHeader__sticker {
  display: block;
}
@media (min-width: 1023px ) {
  .ZaPageHeader__brand {
    font-size: 36px;
  }
  .ZaPageHeader__title {
    padding-bottom: 2em;
    font-size: 74px;
    line-height: 1.2em;
  }
  .ZaPageHeader--type-page .ZaPageHeader__title {
    padding-top: 1em;
  }
}
@media (max-width: 1023px ) {
  .ZaPageHeader__brand {
    padding-top: 1.5rem;
    font-size: 24px;
  }
  .ZaPageHeader__title {
    padding-bottom: 2em;
    color: #2A4960;
    font-size: 56px;
    line-height: 1.3em;
  }
  .ZaPageHeader--type-page .ZaPageHeader__title {
    padding-top: 2em;
  }
}
