.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .ZaSlider .vueperslides {
  outline: 1px solid green;
}
[showBorders3] .ZaSlider .vueperslide {
  outline: 1px solid blue;
}
[showBorders3] .ZaSlider__mobileSlideBu {
  background-color: rgba(255, 0, 0, 0.2);
}
[showBorders3] .ZaSlider__slideBu {
  background-color: rgba(255, 255, 0, 0.35);
}
.ZaSlider__inner {
  margin: 0 auto;
}
.ZaSlider .vueperslides--fixed-height.vueperslides--bullets-outside {
  margin: 0;
}
.ZaSlider .vueperslide {
  position: relative;
}
.ZaSlider__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ZaSlider__slideImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ZaSlider__slideBu {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0em;
}
.ZaSlider__mobileSlideBu {
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
.ZaSlider__mobileSlideBu:empty {
  display: none;
}
.ZaSlider .vueperslides__parallax-wrapper::before,
.ZaSlider .vueperslides__parallax-wrapper::after {
  box-shadow: none !important;
}
.ZaSlider__slideBu {
  padding-top: 5em;
  padding-right: 1em;
}
