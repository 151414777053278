.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}.MhRouterView {
  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: ease;
  opacity: 0;
}
.MhRouterView.fade-enter-active,
.MhRouterView.fade-leave-active {
  transition-duration: 150ms;
  transition-property: opacity;
  transition-timing-function: ease;
}
.MhRouterView.fade-enter-active {
  transition-delay: 0ms !important;
}
.MhRouterView.fade-enter,
.MhRouterView.fade-leave-active {
  opacity: 0;
  transition-delay: 0ms !important;
}
.MhRouterView--showContent {
  transition-delay: 50ms;
  opacity: 1;
}
.MhRouterViewWrapper__debug {
  display: none !important;
  position: fixed;
  bottom: 2em;
  left: 2em;
  padding: 0.5em;
  background-color: rgba(0, 128, 0, 0.75);
}
.MhRouterViewWrapper__spinner {
  position: fixed;
  top: 50vh;
  left: 50vw;
  height: 2em;
  width: 2em;
  transform: translateX(-50%) translateY(-50%) scale(1.2);
  pointer-events: none;
  transition: all 350ms ease-out;
  stroke-width: 5px;
  color: var(--color-primary-shadow);
  opacity: 0;
}
.MhRouterViewWrapper__spinner circle {
  stroke-opacity: 0.5;
}
.MhRouterViewWrapper__spinner--isVisible {
  transition-delay: 100ms;
  opacity: 0.75;
}
@media (max-width: 1023px ) {
  .MhRouterViewWrapper__spinner {
    stroke-width: 3px;
  }
}
