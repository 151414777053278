// @ is an alias to /src

@fontPath: "@/../../assets/fonts/";

@font-face{
	font-family:"DIN W03 Regular";
	src: url("@{fontPath}/FS-WebFonts-1440041708/Fonts/5591090/bd87cdfe-d05d-4b74-8896-1177f3dbb585.woff2") format("woff2"),
			url("/FS-WebFonts-1440041708/Fonts/5591090/4d27f3a7-2889-440f-a415-734d7d9e80a7.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face{
	//font-family:"DIN W03 Bold";
	font-family:"DIN W03 Regular";
	src: url("@{fontPath}/FS-WebFonts-1440041708/Fonts/5591151/634fc7fc-a734-47f2-9530-a91dbcc4fd4b.woff2") format("woff2"),
	url("/FS-WebFonts-1440041708/Fonts/5591151/56c766e2-7578-4ae7-8531-1c063c276d37.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
